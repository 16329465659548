<h1 class="mb-4">Banktjenester i regnskapsprogrammet</h1>

<label class="uni-label">
    <span class="mb-2">
        Velg banktjenestene du ønsker å ha i regnskapsprogrammet, for
        {{ onboardingService.state().Customer.Name }}
    </span>

    <uni-radio-group class="mt-2" [(ngModel)]="useCustomSetup" (ngModelChange)="useStandardChange()">
        <uni-radio-button [value]="false">Standardoppsett (anbefalt)</uni-radio-button>
        <uni-radio-button [value]="true">Tilpass oppsett selv</uni-radio-button>
    </uni-radio-group>
</label>

<div class="services flex-column gap-1 border-top">
    <div class="service">
        @if (useCustomSetup) {
            <div class="service-header flex align-center">
                <rig-checkbox [(ngModel)]="state.HasPayments" (ngModelChange)="onStateChange()">
                    <span class="checkbox-label">Betale fra regnskapsprogram</span>
                </rig-checkbox>
            </div>

            @if (state.HasPayments) {
                <div class="service-content">
                    <uni-radio-group
                        [(ngModel)]="state.HasPreApprovedPayment"
                        (ngModelChange)="onStateChange()"
                        class="vertical mt-1"
                    >
                        <uni-radio-button [value]="true"
                            >Jeg vil godkjenne betalinger i regnskapsprogrammet (anbefalt)</uni-radio-button
                        >
                        <uni-radio-button [value]="false"
                            >Jeg vil ettergodkjenne betalinger i nettbanken</uni-radio-button
                        >
                    </uni-radio-group>
                </div>
            }
        } @else {
            <div class="service-header flex align-center">
                <svg fill="none" width="22" viewBox="0 0 24 24">
                    <path
                        d="m5.5 13 4 5.4c.3.3.7.3.9 0l8.4-12.8"
                        stroke="#008A00"
                        stroke-width="2.5"
                        stroke-linecap="round"
                    />
                </svg>
                <strong>Betale fra regnskapsprogram</strong>
            </div>

            <div class="service-content">Betalinger fra regnskapsprogrammet blir automatisk utført i banken</div>
        }
    </div>

    <div class="service">
        <div class="service-header flex align-center">
            @if (useCustomSetup) {
                <rig-checkbox [(ngModel)]="state.HasStatements" (ngModelChange)="onStateChange()">
                    <span class="checkbox-label">Få informasjon om alle banktransaksjoner</span>
                </rig-checkbox>
            } @else {
                <svg fill="none" width="22" viewBox="0 0 24 24">
                    <path
                        d="m5.5 13 4 5.4c.3.3.7.3.9 0l8.4-12.8"
                        stroke="#008A00"
                        stroke-width="2.5"
                        stroke-linecap="round"
                    />
                </svg>
                <strong>Få informasjon om alle banktransaksjoner</strong>
            }
        </div>

        <div class="service-content">
            <span
                >Informasjon om gjennomførte inn- og utbetalinger sendes automatisk fra banken til regnskapsprogrammet,
                og benyttes i bankavstemming.</span
            >

            @if (state.HasStatements) {
                <label class="uni-label label-above mt-2">
                    <span>Velg når du ønsker å starte bankavstemming</span>
                    <uni-datepicker
                        [(value)]="state.BankStatementStartDate"
                        class="w-50"
                        (valueChange)="onStateChange()"
                    ></uni-datepicker>
                </label>
            }
        </div>
    </div>
</div>

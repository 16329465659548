<h1>Har {{ onboardingService.state().Customer.Name }} allerede et regnskap?</h1>

<label class="uni-label mt-3">
    <span> Har det blitt ført regnskap for {{ onboardingService.state().Customer.Name }} tidligere? </span>
    <uni-radio-group [(ngModel)]="hasExistingReconciliation" (change)="onSelectionChange()" class="vertical">
        <uni-radio-button [value]="false"
            >Nei, det har ikke blitt ført regnskap for foretaket tidligere</uni-radio-button
        >
        <uni-radio-button [value]="true">Ja, det har blitt ført regnskap for foretaket tidligere</uni-radio-button>
    </uni-radio-group>
</label>

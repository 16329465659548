<h1 class="mt-2 mb-2">Banktjenester</h1>

<h3>Dette er banktjenester i standardoppsettet i Eika Regnskap</h3>

<ul>
    @for (info of bankServicesInformation; track info.title) {
        <li class="flex gap-2">
            <i class="material-icons color-good">task_alt</i>
            <section>
                <span class="d-block" style="font-weight: 600"> {{ info.title }} </span>
                <span> {{ info.text }} </span>
            </section>
        </li>
    }
</ul>

<uni-radio-group
    [(ngModel)]="useCustomSetup"
    class="flex-column use-standard-group"
    (ngModelChange)="useStandardChange()"
>
    <uni-radio-button [value]="false">Jeg ønsker å bruke standardoppsettet</uni-radio-button>
    <uni-radio-button [value]="true">Jeg ønsker å tilpasse banktjenestene</uni-radio-button>
</uni-radio-group>

@if (useCustomSetup) {
    <div class="flex-column gap-2">
        <rig-checkbox [(ngModel)]="state.HasPayments" (ngModelChange)="onStateChange()">
            Betaling rett fra regnskapssystemet
        </rig-checkbox>

        <rig-checkbox [(ngModel)]="state.HasStatements" (ngModelChange)="onStateChange()">
            Bankavstemming
        </rig-checkbox>
    </div>
}
@if (state.HasStatements) {
    <label class="uni-label label-above mt-4">
        <span>Velg oppstartsdato for bankavstemming</span>
        <uni-datepicker
            [(value)]="state.BankStatementStartDate"
            class="w-50"
            (valueChange)="onStateChange()"
        ></uni-datepicker>
    </label>
}
